/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useStaticQuery, Link, graphql } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import { Modal } from "./layout/modal"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "../client/apollo"
import PageContext from "../context/PageContext"

const navigation = {
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/malgenservices/",
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "LinkedIn",
      href:
        "https://www.linkedin.com/company/malgen-services-s-r-o-?trk=biz-companies-cym",
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://instagram.com/malgenservices?ref=badge",
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UC-wGHBDMo2o8CbD-d70MWQQ",
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M4.652 0h1.44l.988 3.702.916-3.702h1.454l-1.665 5.505v3.757h-1.431v-3.757l-1.702-5.505zm6.594 2.373c-1.119 0-1.861.74-1.861 1.835v3.349c0 1.204.629 1.831 1.861 1.831 1.022 0 1.826-.683 1.826-1.831v-3.349c0-1.069-.797-1.835-1.826-1.835zm.531 5.127c0 .372-.19.646-.532.646-.351 0-.554-.287-.554-.646v-3.179c0-.374.172-.651.529-.651.39 0 .557.269.557.651v3.179zm4.729-5.07v5.186c-.155.194-.5.512-.747.512-.271 0-.338-.186-.338-.46v-5.238h-1.27v5.71c0 .675.206 1.22.887 1.22.384 0 .918-.2 1.468-.853v.754h1.27v-6.831h-1.27zm2.203 13.858c-.448 0-.541.315-.541.763v.659h1.069v-.66c.001-.44-.092-.762-.528-.762zm-4.703.04c-.084.043-.167.109-.25.198v4.055c.099.106.194.182.287.229.197.1.485.107.619-.067.07-.092.105-.241.105-.449v-3.359c0-.22-.043-.386-.129-.5-.147-.193-.42-.214-.632-.107zm4.827-5.195c-2.604-.177-11.066-.177-13.666 0-2.814.192-3.146 1.892-3.167 6.367.021 4.467.35 6.175 3.167 6.367 2.6.177 11.062.177 13.666 0 2.814-.192 3.146-1.893 3.167-6.367-.021-4.467-.35-6.175-3.167-6.367zm-12.324 10.686h-1.363v-7.54h-1.41v-1.28h4.182v1.28h-1.41v7.54zm4.846 0h-1.21v-.718c-.223.265-.455.467-.696.605-.652.374-1.547.365-1.547-.955v-5.438h1.209v4.988c0 .262.063.438.322.438.236 0 .564-.303.711-.487v-4.939h1.21v6.506zm4.657-1.348c0 .805-.301 1.431-1.106 1.431-.443 0-.812-.162-1.149-.583v.5h-1.221v-8.82h1.221v2.84c.273-.333.644-.608 1.076-.608.886 0 1.18.749 1.18 1.631v3.609zm4.471-1.752h-2.314v1.228c0 .488.042.91.528.91.511 0 .541-.344.541-.91v-.452h1.245v.489c0 1.253-.538 2.013-1.813 2.013-1.155 0-1.746-.842-1.746-2.013v-2.921c0-1.129.746-1.914 1.837-1.914 1.161 0 1.721.738 1.721 1.914v1.656z" />
        </svg>
      ),
    },
  ],
}

const Layout = ({ hide, location, ...props }) => {
  const data = useStaticQuery(graphql`
    query Pages {
      scomos {
        pages(menu: "main") {
          id: _id
          parent
          menuName
          menu
          url
          lft
        }
      }
    }
  `)

  const [menu, setMenu] = useState([])
  const [malgenMenu, setMalgenMenu] = useState([])

  const [context, dispatch] = useContext(PageContext)

  useEffect(() => {
    if (!hide) {
      let items = []
      let malgenMenu = []
      let mainId = 0

      function menuItems() {
        data.scomos.pages.forEach(menuItem => {
          if (menuItem.parent === 0) {
            mainId = menuItem.id
          }
          items[menuItem.id] = menuItem
        })

        let newItems = [...items]
        items.forEach(menuItem => {
          if (items[menuItem.parent]) {
            if (!items[menuItem.parent]["children"]) {
              newItems[menuItem.parent]["children"] = []
            }
            newItems[menuItem.parent]["children"][menuItem.lft] = menuItem
          }
        })

        let sorted = []
        let i = 0
        newItems[mainId]["children"].forEach(newMenuItem => {
          sorted[newMenuItem.lft] = newMenuItem
          i++
          if (i > 3) malgenMenu[i] = newMenuItem
        })

        return sorted
      }

      let newMenu = menuItems()
      setMenu(newMenu)

      dispatch({ type: "SET_MENU", payload: { menu: newMenu } })
      setMalgenMenu(malgenMenu)
    }
    // eslint-disable-next-line
  }, [])

  if (hide) {
    return <>{props.children}</>
  } else {
    return (
      <>
        <ApolloProvider client={apolloClient}>
          <div className="relative">
            <Header menu={menu} location={location} />
            <main>{props.children}</main>
          </div>
          <footer className="bg-gray-800" aria-labelledby="footerHeading">
            <h2 id="footerHeading" className="sr-only">
              Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <div className="pb-8 xl:grid xl:grid-cols-4 xl:gap-8">
                <div className="grid grid-cols-2 gap-8 xl:col-span-4">
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Reality
                      </h3>
                      <ul className="mt-4 space-y-4">
                        {menu.length > 1 &&
                          menu[4] &&
                          menu[4].children &&
                          menu[4].children.length > 1 &&
                          menu[4].children.map(item => (
                            <li key={item.id}>
                              <Link
                                to={"/" + item.url}
                                className="text-base text-gray-300 hover:text-white"
                              >
                                {item.menuName}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="mt-12 md:mt-0">
                      <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Ostraha a správa
                      </h3>
                      <ul className="mt-4 space-y-4">
                        {menu.length > 1 &&
                          menu[18].children.map(item => (
                            <li key={item.id}>
                              <Link
                                to={"/" + item.url}
                                className="text-base text-gray-300 hover:text-white"
                              >
                                {item.menuName}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Poradenství
                      </h3>
                      <ul className="mt-4 space-y-4">
                        {menu.length > 1 &&
                          menu[30].children.map(item => (
                            <li key={item.id}>
                              <Link
                                to={"/" + item.url}
                                className="text-base text-gray-300 hover:text-white"
                              >
                                {item.menuName}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="mt-12 md:mt-0">
                      <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                        Malgen
                      </h3>
                      <ul className="mt-4 space-y-4">
                        {malgenMenu.length > 1 &&
                          malgenMenu.map(item => (
                            <li key={item.id}>
                              <Link
                                to={"/" + item.url}
                                className="text-base text-gray-300 hover:text-white"
                              >
                                {item.menuName}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-700 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                    Holding MALGEN
                  </h3>
                  <p className="text-base text-gray-300">
                    V Tůních 1770/10, 120 00 Praha 2
                  </p>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
                <div className="flex space-x-6 md:order-2">
                  {navigation.social.map(item => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-gray-400 hover:text-gray-300"
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
                <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
                  &copy; {new Date().getFullYear()} Holding MALGEN
                  <Link to={"/gdpr"} className="ml-5 text-gray-200">
                    Zásady ochrany osobních údajů
                  </Link>
                </p>
              </div>
            </div>
          </footer>
          <Modal />
        </ApolloProvider>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
