/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"

import React, { useReducer, useEffect, useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageContext from "./src/context/PageContext"
import { Reducer, SET_MENU, SET_ITEMS } from "./src/context/reducers"
import axiosInstance from "./src/client/index.js"
import SimpleReactLightbox from "simple-react-lightbox"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "./src/client/apollo"

let logoutTimer

const App = props => {
  const [cartId, setCartId] = useState(localStorage.getItem("cartId"))
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("userData"))?.accessToken
  )
  const [tokenExpirationDate, setTokenExpirationDate] = useState()

  const addProductToCart = async product => {
    axiosInstance
      .post("cart/item_add/" + state?.cart?.cart?.cartHash + "/", {
        itemId: product.id,
        type: "goods",
        price: product.priceVat,
      })
      .then(res => {
        dispatch({ type: SET_ITEMS, data: res.data })
      })
      .catch(err => {
        console.log(err)
        // This probably means your response is text, do you text handling here
      })
  }

  const login = useCallback((data, expirationTime) => {
    if (data) {
      setToken(data.accessToken)
      const expiration =
        expirationTime || new Date(new Date().getTime() + 1000 * 60 * 60 * 3)
      setTokenExpirationDate(expiration)
      localStorage.setItem(
        "userData",
        JSON.stringify({
          accessToken: data.accessToken,
          usersDataId: data.usersDataId,
          email: data.email,
          expirationTime: expiration.toISOString(),
          hash: data.hash,
        })
      )
      dispatch({
        type: "SET_USER",
        data: {
          usersDataId: data.usersDataId,
          hash: data.hash,
        },
      })
    }
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    localStorage.removeItem("userData")
    localStorage.removeItem("userDataStatus")
    dispatch({ type: "CLEAR_USER" })
  }, [])

  const setItems = async data => {
    dispatch({ type: SET_ITEMS, data: data })
  }

  function updateCartId() {
    let cartHash
    cartHash =
      "cart_" +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    localStorage.setItem("cartId", cartHash)
    setCartId(cartHash)
  }

  const initialState = {
    cart: {},
    lastProduct: {},
    menu: [],
    cats: [],
    breadCrumbPath: [],
    setItems: setItems,
    addProductToCart: addProductToCart,
    isLoggedIn: !!token,
    userData: JSON.parse(localStorage.getItem("userData")),
    token: token,
    login: login,
    logout: logout,
    modal: { open: false },
    miniCart: { open: false },
    storage: false,
    searchString: null,
  }

  const [state, dispatch] = useReducer(Reducer, initialState)

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"))
    if (
      storedData &&
      storedData.accessToken &&
      new Date(storedData.expirationTime) > new Date()
    ) {
      login(storedData, new Date(storedData.expirationTime))
    }
  }, [login])

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime()
      logoutTimer = setTimeout(logout, remainingTime)
    } else {
      clearTimeout(logoutTimer)
    }
  }, [token, logout, tokenExpirationDate])

  useEffect(() => {
    // if (!cartId) {
    //   updateCartId()
    // }
    // async function getItems() {
    //   try {
    //     await axiosInstance.post("cart/items/" + cartId + "/").then(res => {
    //       setItems(res.data)
    //     })
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }
    // getItems()
    // eslint-disable-next-line
  }, [])

  return (
    <PageContext.Provider value={[state, dispatch]}>
      {props.children}
    </PageContext.Provider>
  )
}

export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>
    <App>{element}</App>
  </SimpleReactLightbox>
)
